<template>
  <div class="search-input">
    <el-input v-model="searchText" :placeholder="placeholder" @keyup.enter.native="change" @blur="change">
      <i slot="suffix" class="el-input__icon iconfont icon-dcsousuo" @click="change"></i>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容...'
    }
  },
  data() {
    return {
      searchText: ''
    };
  },
  methods: {
    change() {
      this.$emit('change', this.searchText);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__suffix {
  width: 30px;
  right: 0;
  cursor: pointer;
}
</style>
