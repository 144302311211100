<template>
  <div class="base-table">
    <el-table :height="height" :data="data" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55" v-if="multi"></el-table-column>
      <el-table-column label="序号" align="center" width="55">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column v-for="column in scopeColumns" :key="column.prop" :prop="column.prop" :label="column.label" :width="column.width" :align="column.align || 'left'">
        <template slot-scope="scope">
          <div class="column_href" @click="column.handle(scope.row)" v-if="column.href"><i class="iconfont icon-dcicon_link"></i>{{ scope.row[column.prop] }}</div>
          <div class="ellipsis" v-else>{{ scope.row[column.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" :align="operations.align ? operations.align : 'right'" v-if="operations">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="scope">
          <span class="el-dropdown-link">{{ operations.text ? '' : '操作' }}</span>
          <el-dropdown trigger="click">
            <i class="iconfont icon-dcshaixuan1"></i>
            <el-dropdown-menu slot="dropdown">
              <el-checkbox-group v-model="checkColumns">
                <el-checkbox v-for="column in columns" :key="column.prop" :label="column.label"></el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <div v-if="isMore">
            <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon + scope.row.createdTime" @click="handle(scope.row)">
              <el-tooltip :content="name" placement="top">
                <i :class="icon" class="iconfont"></i>
              </el-tooltip>
            </el-button>
            <el-dropdown trigger="click">
              <el-button type="text" size="small">
                <el-tooltip content="更多" placement="top">
                  <i :class="TABLE.icons.more" class="iconfont"></i>
                </el-tooltip>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="text-align: center" v-for="{ icon, name, handle } in moreAfter" :key="icon">
                  <div @click="handle(scope.row)">
                    <i class="iconfont"></i>
                    <span> {{ name }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-else>
            <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon + scope.row.createdTime" @click="handle(scope.row)">
              <el-tooltip :content="name" placement="top">
                <i :class="icon" class="iconfont" v-if="scope.row.deviceTypeId && name === '解绑'"></i>
                <i :class="icon" class="iconfont" v-if="name !== '解绑' && name !== '绑定'"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" v-for="{ icon, name, handle } in moreBefore" :key="icon" @click="handle(scope.row)">
              <el-tooltip :content="name" placement="top">
                <i :class="icon" class="iconfont" v-if="!scope.row.deviceTypeId && name === '绑定'"></i>
              </el-tooltip>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { SIMULATE_NETWORK_TIME, TABLE } from '/app.config';
export default {
  props: {
    // 是否支持多选
    multi: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    // 表格的 columns
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    // 表格的操作
    operations: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      TABLE,
      height: 0,
      loading: false,
      scopeColumns: this.columns,
      checkColumns: this.columns.map(column => column.label)
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (document.querySelector('.list-container > .main')) {
        this.height = document.querySelector('.list-container > .main').clientHeight;
      } else {
        this.height = '500px';
      }
    });
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('update:multipleSelection', val);
    }
  },
  computed: {
    isMore() {
      return this.operations.handles.length > TABLE.more_icons_threshold;
    },
    moreBefore() {
      return this.operations.handles.filter((handle, index) => index < TABLE.more_icons_threshold);
    },
    moreAfter() {
      return this.operations.handles.filter((handle, index) => index >= TABLE.more_icons_threshold);
    }
  },
  watch: {
    checkColumns(val) {
      this.loading = true;
      setTimeout(() => {
        this.scopeColumns = this.columns.filter(cloumn => {
          return val.includes(cloumn.label);
        });
        this.loading = false;
      }, SIMULATE_NETWORK_TIME);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import '../table';
.base-table {
  height: 100%;
  .column_href {
    &:hover {
      color: $--color-primary;
      cursor: pointer;
      .icon-dcicon_link {
        color: $--color-primary;
      }
    }
    .icon-dcicon_link {
      color: #909399;
      margin-right: 8px;
      display: inline-block;
    }
  }
}
</style>
