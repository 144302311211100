<template>
  <div class="list-container">
    <div class="header">
      <div class="addOrdel">
        <el-button class="iconfont icon-dctianjia" style="margin-right: 10px" @click="$emit('add')" v-if="isAdd">{{ addText }}</el-button>
        <el-button class="iconfont icon-dcshanchu" type="danger" plain @click="$emit('multipleDel')" v-if="multipleDelCheck">删除</el-button>
      </div>
      <div class="file" v-if="uploadFile.length">
        <el-button class="iconfont icon-dcarrow-left" @click="$router.go(-1)" v-if="uploadFile.includes('quit')">返回</el-button>
        <el-button class="iconfont icon-dcdaoru" @click="importFile" v-if="uploadFile.includes('import')">导入</el-button>
        <el-button class="iconfont icon-dcdaochu1" @click="$emit('exportFile')" v-if="uploadFile.includes('export')">导出</el-button>
        <el-button class="iconfont icon-dcxiazai1" @click="$emit('downTemplate')" v-if="uploadFile.includes('download')">下载导入模板</el-button>
      </div>
      <div class="slot">
        <slot></slot>
      </div>
      <div class="right">
        <div class="search" v-if="search">
          <div class="input" :style="{ marginRight: grid ? '17px' : '' }">
            <searchInput :placeholder="placeholder" @change="onSearch" />
          </div>
          <div class="btn_more">
            <!-- <el-button class="iconfont icon-dcicon_more1" @click="$emit('moreClick')">{{ moreName }}</el-button> -->
            <slot name="morebtn"></slot>
          </div>
        </div>
        <div class="switch" v-if="grid">
          <div class="btn-grid" v-if="isTable">
            <el-button type="text" @click="switchType('grid')"><img src="./img/grid.png" alt="" /></el-button>
          </div>
          <div class="btn-table" v-else>
            <el-button type="text" @click="switchType('table')"><img src="./img/table.png" alt="" /></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main" :class="{ gridScrollY }">
      <div class="grid-wrap" style="overflow: auto" v-if="isGrid">
        <slot name="grid"></slot>
      </div>
      <div class="table-wrap" v-else>
        <slot name="table"></slot>
      </div>
    </div>
    <div class="footer clearfix" v-if="pagination">
      <pagination class="pagination fr" v-bind="pagination" @fetch="fetch" />
    </div>
  </div>
</template>

<script>
import pagination from '@/components/pagination/pagination.vue';
import searchInput from '@/components/input/search-input';
export default {
  props: {
    isAdd: {
      type: Boolean,
      default: true
    },
    addText: {
      type: String,
      default: '添加'
    },
    placeholder: String,
    grid: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    uploadFile: {
      type: Array,
      default: () => []
    },
    customReferenceCountKey: String,
    multipleSelection: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object
    }
  },
  data() {
    return {
      gridScrollY: false,
      type: this.grid ? 'grid' : 'table'
    };
  },

  methods: {
    importFile() {
      const file = null;
      this.$emit('uploadDone', file);
    },
    switchType(type) {
      this.type = type;
    },
    onSearch(searchText) {
      this.$emit('update:searchText', searchText);
    },
    fetch(params) {
      this.$emit('fetch', params);
    }
  },
  computed: {
    isGrid() {
      return this.type === 'grid';
    },
    isTable() {
      return this.type === 'table';
    },
    multipleDelCheck() {
      return this.multipleSelection.length && !this.multipleSelection.some(item => item[this.customReferenceCountKey] > 0);
    }
  },
  components: {
    pagination,
    searchInput
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .slot {
      flex: 1;
      font-size: initial;
    }
    .right {
      display: flex;
      align-items: center;
      .search {
        display: flex;
        .input {
          /deep/ .el-input {
            width: 260px;
          }
        }
        .btn_more {
          margin-right: 15px;
        }
      }
      .switch {
        display: flex;
        align-items: center;
        .btn-grid,
        .btn-table {
          margin-right: 5px;
        }
      }
    }
  }
  .main {
    flex: 1;
    overflow-y: hidden;
    .grid-wrap,
    .table-wrap {
      height: 100%;
    }
  }
  .gridScrollY {
    overflow-y: auto;
  }
  .footer {
    margin-top: 31px;
  }
}
.main {
  padding: 0 !important;
}
</style>
